export function serverSideLucidFetcher(query: string, variables = {}) {
  const endpoint = process.env.NEXT_PUBLIC_LUCID_API_ENDPOINT as string
  const apiKey = process.env.LUCID_API_KEY as string

  return fetch(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': apiKey,
    },
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
    })
  })
}


export async function lucidDataFetcherV2<T>(query: string, variables: {[key: string]: any} = {}):
  Promise<{data: {[resolver: string]: T}} & {errors?: Record<string, any>[]}>
{
  const body = {
    query,
    variables,
  }
  const raw = await fetch('/api/graphql-auth', {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await raw.json()
  if (data.error) throw new Error(data.error)
  return data
}

export async function eagerPaginationFetcher<T>(query: string, variables: {[key: string]: any} = {}): Promise<T[]>
{
  const response: T[] = []
  const take = 25
  let skip = 0
  while (true) {
    const {data} = await lucidDataFetcherV2<T[]>(query, {...variables, skip, take})
    const result = Object.values(data)[0]
    response.push(...result)
    if (result.length < take) {
      break
    }
    skip += take
  }
  return response
}
